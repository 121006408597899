<template>
  <div>
    <!-- search -->
    <!-- 表单元素 -->

    <el-table
      :data="tableData"
      size="mini"
      height="510px"
      v-loading="loading"
      element-loading-text="加载中..."
      :border="true"
      :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
      @select="select"
      @select-all="selectAll"
      :defaultSelections="defaultSelections"
    >
      <el-table-column v-if="tableSetting.selection || false" type="selection" align="center"></el-table-column>
      <el-table-column
        v-if="tableSetting.isIndex || true"
        type="index"
        label="序号"
        align="center"
        width="50"
      ></el-table-column>

      <!-- 正式数据/根据条件 -->
      <el-table-column
        :sortable="item.sortable || false"
        :show-overflow-tooltip="item.showTooltip || true"
        v-for="(item, index) of tableSetting.tableList"
        :prop="item.prop"
        :key="index"
        :align="item.align"
        :fixed="item.fixed"
        :label="item.label"
        :width="item.width"
      >
        <template slot-scope="scope">
          <!-- 如果是button -->
          <div v-if="item.button">
            <el-button
              :circle="btn.circle"
              :plain="btn.plain"
              :round="btn.round"
              :type="btn.type"
              :size="btn.size || 'mini'"
              :icon="btn.icon"
              :style="{ color: btn.color }"
              style="width: auto; margin: 0 3px"
              v-for="(btn, i) of item.button"
              :key="i"
              v-show="!btn.isShow || (btn.isShow && btn.isShow(scope.row))"
              :disabled="btn.isDisabled && btn.isDisabled(scope.row)"
              @click="sendClick(btn, scope.row)"
            >{{ btn.label }}</el-button>
          </div>
          <!-- 如果是图片 -->
          <div v-else-if="item.type === 'img'" style="width: 30px; height: 30px">
            <el-image
              style="width: 30px; height: 30px"
              :src="scope.row[item.prop]"
              :preview-src-list="[scope.row[item.prop]]"
            ></el-image>
          </div>
          <!-- 进行一次自定义过滤值,再渲染 -->
          <div v-else v-html="getValue(scope, item)"></div>
        </template>
      </el-table-column>
    </el-table>

    <div v-if="tableSetting.pagination" class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="curPage"
        :page-sizes="[10, 15, 20, 25, 30, 40, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="parseInt(total)"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import { getList } from "./tablesearch";
export default {
  props: {
    tableSetting: {
      type: Object,
      default: () => {}
    },
    handleClick: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      tableData: [],
      loading: true,
      defaultSelections: [],
      curPage: 1,
      pageSize: 10,
      total: 0
    };
  },
  watch: {},
  mounted() {
    this.getPageData();
  },
  methods: {
    // 表格勾选
    select(rows, row) {
      console.log(rows, row);
      this.$emit("select", rows, row);
    },
    // 全选
    selectAll(rows, row) {
      console.log(rows);
      this.$emit("select", rows);
    },
    // 过滤值
    getValue(scope, item) {
      return item.changeValue
        ? item.changeValue(scope.row)
        : scope.row[item.prop];
    },
    // 点击事件
    sendClick(btn, row) {
      this.$emit("handleClick", { btnName: btn.btnName, row: row });
    },
    // 获取本页数据
    getPageData() {
      this.loading = true;
      getList(this.tableSetting.urlName, this.tableSetting.params).then(res => {
        console.log(res);
        if (res.code == 0) {
          setTimeout(() => {
            this.tableData = res.data;
            this.total = res.count;
            this.loading = false;
          }, 800);
        }
      });
    },
    // 重新加载
    reload() {
      console.log("重新加载了一遍");
      this.getPageData();
    },
    // 页数发生改变
    handleSizeChange(val) {
      console.log(val);
      this.tableSetting.params.pageSize = val;
      // this.queryPage();
    },
    // 页数发生改变
    handleCurrentChange(val) {
      console.log(val);
      this.tableSetting.params.curPage = val;
      // this.curPage = val;
      // this.queryPage();
    }
  }
};
</script>
<style>
.pagination {
  width: 100%;
  text-align: right;
  padding: 20px 20px 0;
}
</style>