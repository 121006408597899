import router from "./src/router";
import Cookies from "js-cookie";
import NProgress from "nprogress";
import store from "./src/store";
import "nprogress/nprogress.css";
import { Message } from "element-ui";

router.beforeEach((to, from, next) => {
  NProgress.start();
  if (Cookies.get("token")) {
    if (to.path == "/" || to.path == "/login") {
      Cookies.remove("token");
      Cookies.remove("role");
      if (Cookies.get("rememberMe") == 0) {
        Cookies.remove("username");
        Cookies.remove("password");
      }
      next();
    }else {
     next()
    }
  } else {
    next();
    NProgress.done();
    // Message.error("登录失效,请重新登录...");
    // setTimeout(() => {
    //   next("/login");
    //   NProgress.done();
    // }, 100);
  }
});

router.afterEach(() => {
  NProgress.done();
});
