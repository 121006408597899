// import parseTime, formatTime and set to filter

/**
 * Show plural label if time is plural number
 * @param {number} time
 * @param {string} label
 * @return {string}
 */
import {
  getUploadToken
} from "../request/http";
import {
  Notification
} from "element-ui";
import * as qiniu from "qiniu-js";
import {
  MessageBox
} from 'element-ui'
// 多张图片上传
// 1.普通分享 2.pdf胶囊按钮分享 3.pdf海报分享 4.名片胶囊按钮分享 5.名片海报分享 6.资讯分享 7.资讯海报分享
export function textChoose(number) {
  let text
  switch (number) {
    case 1:
      text = '普通分享'
      break;
    case 2:
      text = 'pdf胶囊按钮分享'
      break;
    case 3:
      text = 'pdf海报分享'
      break;
    case 4:
      text = '名片胶囊按钮分享'
      break;
    case 5:
      text = '名片海报分享'
      break;
    case 6:
      text = '资讯分享'
      break;
    case 7:
      text = '资讯海报分享'
      break;


    default:
      break;
  }
  return text
}



const formatNumber = n => {
  n = n.toString()
  return n[1] ? n : '0' + n
}
export function formatTimeTwo(number, format) {
  var formateArr = ['Y', 'M', 'D', 'h', 'm', 's'];
  var returnArr = [];
  var date = new Date(number * 1000);
  returnArr.push(date.getFullYear());
  returnArr.push(formatNumber(date.getMonth() + 1));
  returnArr.push(formatNumber(date.getDate()));
  returnArr.push(formatNumber(date.getHours()));
  returnArr.push(formatNumber(date.getMinutes()));
  returnArr.push(formatNumber(date.getSeconds()));
  for (var i in returnArr) {
    format = format.replace(formateArr[i], returnArr[i]);
  }
  return format;
}

export default {
  morePicture: function (evfile) {
    return new Promise((resolve, reject) => {
      var targetFiles = evfile.target.files;
      var fileTypes = ["gif", "jpg", "jpeg", "png"];
      for (let i = 0; i < targetFiles.length; i++) {
        if (fileTypes.includes(targetFiles[i].name.split(".")[1])) {
          // 获取token上传七牛云
          getUploadToken({
            fileName: evfile.target.files[i].name
          }).then(
            res => {
              var uptoken = res.data.token;
              var file = evfile.target.files[i]; // Blob 对象，上传的文件
              var key = res.data.fid; // 上传后文件资源名以设置的 key 为主，如果 key 为 null 或者 undefined，则文件资源名会以 hash 值作为资源名。

              const config = {
                useCdnDomain: true, // 表示是否使用 cdn 加速域名，为布尔值，true 表示使用，默认为 false。
                region: qiniu.region.z0 // 根据具体提示修改上传地区,当为 null 或 undefined 时，自动分析上传域名区域
              };

              const putExtra = {
                fname: "", // 文件原文件名
                params: {}, // 用来放置自定义变量
                mimeType: null // 用来限制上传文件类型
              };
              var observable = qiniu.upload(
                file,
                key,
                uptoken,
                putExtra,
                config
              );
              observable.subscribe({
                next: result => {
                  // 主要用来展示进度
                  // console.log(result);
                },
                error: errResult => {
                  // 失败报错信息
                  // console.log(errResult);
                },
                complete: result => {
                  // 接收成功后返回的信息
                  // console.log(result);
                  // 从后台获取图片显示
                  // obj.push(result.key)
                  resolve(result.key)
                }
              });

            }
          );

        } else {
          this.$message.error("您选中的某个类型不支持上传,请重新选择");
        }

      }
    });
  },

  // 上传文件
  fileUpdate: function (evfile) {
    return new Promise((resolve, reject) => {
      var targetFiles = evfile.target.files;
      var fileTypes = ["pdf"];
      for (let i = 0; i < targetFiles.length; i++) {
        if (fileTypes.includes(targetFiles[i].name.split(".")[1])) {
          // 获取token上传七牛云
          getUploadToken({
            fileName: evfile.target.files[i].name
          }).then(
            res => {
              var uptoken = res.data.token;
              var file = evfile.target.files[i]; // Blob 对象，上传的文件
              var key = res.data.fid; // 上传后文件资源名以设置的 key 为主，如果 key 为 null 或者 undefined，则文件资源名会以 hash 值作为资源名。

              const config = {
                useCdnDomain: true, // 表示是否使用 cdn 加速域名，为布尔值，true 表示使用，默认为 false。
                region: qiniu.region.z0 // 根据具体提示修改上传地区,当为 null 或 undefined 时，自动分析上传域名区域
              };

              const putExtra = {
                fname: "", // 文件原文件名
                params: {}, // 用来放置自定义变量
                mimeType: null // 用来限制上传文件类型，为 null 时表示不对文件类型限制；限制类型放到数组里： ["image/png", "image/jpeg", "image/gif"]
              };
              var observable = qiniu.upload(
                file,
                key,
                uptoken,
                putExtra,
                config
              );
              observable.subscribe({
                next: result => {
                  // 主要用来展示进度
                  // console.log(result);
                },
                error: errResult => {
                  // 失败报错信息
                  // console.log(errResult);
                },
                complete: result => {
                  // 接收成功后返回的信息
                  // console.log(result);
                  // 从后台获取图片显示
                  // obj.push(result.key)
                  resolve(result.key)
                }
              });

            }
          );

        } else {
          this.errorTip("失败", "请选择上传pdf格式的文档");
        }

      }
    });
  },


  // 时间转化
  time: function (val) {
    var date = new Date(val) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var Y = date.getFullYear() + '-'
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
    var D = date.getDate() + ' '
    var h = date.getHours() + ':'
    var m = date.getMinutes()
    // var s = date.getSeconds()
    return Y + M + D + h + m
  },
  // 标准时间转化
  dateConvert: function (date) {
    var dateBefore = new Date(date);
    let dateAfter =
      dateBefore.getFullYear() +
      "-" +
      (dateBefore.getMonth() + 1 < 10 ?
        "0" + (dateBefore.getMonth() + 1) :
        dateBefore.getMonth() + 1) +
      "-" +
      (dateBefore.getDate() < 10 ?
        "0" + dateBefore.getDate() :
        dateBefore.getDate()) +
      " " +
      (dateBefore.getHours() < 10 ?
        "0" + dateBefore.getHours() :
        dateBefore.getHours()) +
      ":" +
      (dateBefore.getMinutes() < 10 ?
        "0" + dateBefore.getMinutes() :
        dateBefore.getMinutes());
    return dateAfter;
  },
  // elementUI的操作后提示
  successTip: function (title, message, dir) {
    Notification.success({
      title: title,
      message: message,
      position: dir
    });
  },
  errorTip: function (title, message, dir) {
    Notification.error({
      title: title,
      message: message,
      position: dir
    });
  },
  // messageBox封装(删除等二次操作确定)
  handleCofirm(text = '确定执行此操作吗？', type = 'warning') {
    return MessageBox.confirm(text, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: type,
    })
  },
  // 校验判断
  // num>0的正整数
  inputNum: function (val) {
    let reg = /^\+?[1-9]\d*$/;
    if (reg.test(val)) {
      return true;
    } else {
      return false;
    }
  },
  // 
  // phone的
  phoneNum: function (val) {
    if (!/^1[3456789]\d{9}$/.test(val)) {
      return false;
    } else {
      return true;
    }
  }

}

function pluralize(time, label) {
  if (time === 1) {
    return time + label
  }
  return time + label + 's'
}

/**
 * @param {number} time
 */
export function timeAgo(time) {
  const between = Date.now() / 1000 - Number(time)
  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute')
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour')
  } else {
    return pluralize(~~(between / 86400), ' day')
  }
}

/**
 * Number formatting
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */
export function numberFormatter(num, digits) {
  const si = [{
      value: 1E18,
      symbol: 'E'
    },
    {
      value: 1E15,
      symbol: 'P'
    },
    {
      value: 1E12,
      symbol: 'T'
    },
    {
      value: 1E9,
      symbol: 'G'
    },
    {
      value: 1E6,
      symbol: 'M'
    },
    {
      value: 1E3,
      symbol: 'k'
    }
  ]
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
    }
  }
  return num.toString()
}

/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter(num) {
  return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}

/**
 * Upper case first char
 * @param {String} string
 */
export function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }

  if ((time + '').length === 10) {
    time = +time * 1000
  }

  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    date = new Date(parseInt(time))
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    if (key === 'a') {
      return ['一', '二', '三', '四', '五', '六', '日'][value - 1]
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return timeStr
}

export function formatTime(time, option) {
  time = +time * 1000
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}
export function getNowFormatDate() {
  var date = new Date()
  var seperator1 = '-'
  var year = date.getFullYear()
  var month = date.getMonth() + 1
  var strDate = date.getDate()
  if (month >= 1 && month <= 9) {
    month = '0' + month
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = '0' + strDate
  }
  var currentdate = year + seperator1 + month + seperator1 + strDate
  return currentdate
}
/* 数字 格式化 */
export function nFormatter(num, digits) {
  const si = [{
      value: 1e18,
      symbol: 'E'
    },
    {
      value: 1e15,
      symbol: 'P'
    },
    {
      value: 1e12,
      symbol: 'T'
    },
    {
      value: 1e9,
      symbol: 'G'
    },
    {
      value: 1e6,
      symbol: 'M'
    },
    {
      value: 1e3,
      symbol: 'k'
    }
  ]
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (
        (num / si[i].value + 0.1)
        .toFixed(digits)
        .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
      )
    }
  }
  return num.toString()
}

export function html2Text(val) {
  const div = document.createElement('div')
  div.innerHTML = val
  return div.textContent || div.innerText
}

export function toThousandslsFilter(num) {
  return (+num || 0)
    .toString()
    .replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}
// 验证手机号
export function checkPhone(rule, value, callback) {
  if (!value) {
    return callback(new Error('手机号不能为空'))
  } else {
    const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
    if (reg.test(value)) {
      callback()
    } else {
      return callback(new Error('请输入正确的手机号'))
    }
  }
}
export function checkPassword(rule, value, callback) {
  if (!value) {
    return callback(new Error('密码不能为空'))
  } else if (value.length < 6) {
    callback(new Error('请至少输入 6 个字符。请不要使用容易被猜到的密码'))
  } else {
    callback()
  }
}
// 手机号证验证
export function checkTel(value, callback) {
  var reg = /^1[3|4|5|7|8][0-9]\d{8}$/
  return reg.test(value)
}
// 身份证验证
export function checkiDNumber(value, callback) {
  var reg = /\d{17}[\d|x]|\d{15}/
  return reg.test(value)
}
// 身份证验证
export function checkEmails(value, callback) {
  var reg = /^[A-Za-zd]+([-_.][A-Za-zd]+)*@([A-Za-zd]+[-.])+[A-Za-zd]{2,5}$/
  return reg.test(value)
}
// 邮箱验证
export function checkEmail(rule, value, callback) {
  if (!value) {
    return callback(new Error('邮箱不能为空'))
  } else {
    var reg = /^[A-Za-zd]+([-_.][A-Za-zd]+)*@([A-Za-zd]+[-.])+[A-Za-zd]{2,5}$/
    if (reg.test(value)) {
      callback()
    } else {
      return callback(new Error('请输入正确的邮箱'))
    }
  }
}
// 英文验证
export function checkCode(value, callback) {
  var reg = /^[A-Za-z]+$/g
  return reg.test(value)
}
// qq验证
export function checkQq(value, callback) {
  var reg = /^[0-9]+$/g
  return reg.test(value)
}
// 银行卡号
export function formatBankNo(BankNo, callback) {
  var strBin = '10,18,30,35,37,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,58,60,62,65,68,69,84,87,88,94,95,98,99'
  return strBin
}
export function getStrleng(str, max) {
  var myLen = 0
  for (var i = 0; i < str.length && myLen <= max * 2; i++) {
    if (str.charCodeAt(i) > 0 && str.charCodeAt(i) < 128) {
      myLen++
    } else myLen += 2
  }
  return myLen
}
// 上传图片格式控制
export function updatedImg(file, obj, callback, func) {
  if (file.size < 10100000) {
    var fileName = file.name
    var suffix = fileName
      .substring(fileName.lastIndexOf('.') + 1)
      .toUpperCase()
    if (
      suffix === 'PDF' ||
      suffix === 'JPG' ||
      suffix === 'JPEG' ||
      suffix === 'PNG' ||
      suffix === 'GIF'
    ) {
      return true
    } else {
      var tipType = '文件类型不正确,请重新上传'
      callback(tipType)
      return false
    }
  } else {
    var tipSize = '文件大小超过5M,请重新上传'
    callback(tipSize)
    return false
  }
}
// 上传文档格式控制
export function updatedFile(file, obj, callback, func) {
  if (file.size < 10100000) {
    var fileName = file.name
    var suffix = fileName
      .substring(fileName.lastIndexOf('.') + 1)
      .toUpperCase()
    if (
      suffix === 'DOC' ||
      suffix === 'DOCX' ||
      suffix === 'XLS' ||
      suffix === 'XLSX' ||
      suffix === 'PDF' ||
      suffix === 'ZIP' ||
      suffix === 'RAR'
    ) {
      return true
    } else {
      var tipType = '文件类型不正确,请重新上传'
      callback(tipType)
      return false
    }
  } else {
    var tipSize = '文件大小超过5M,请重新上传'
    callback(tipSize)
    return false
  }
}
export function importFile(file, obj, callback, func) {
  if (file.size < 10100000) {
    var fileName = file.name
    var suffix = fileName
      .substring(fileName.lastIndexOf('.') + 1)
      .toUpperCase()
    if (
      suffix === 'XLS' ||
      suffix === 'XLSX'
    ) {
      return true
    } else {
      var tipType = '文件类型不正确,请重新上传'
      callback(tipType)
      return false
    }
  } else {
    var tipSize = '文件大小超过10M,请重新上传'
    callback(tipSize)
    return false
  }
}
export function minHeight(resfile) {
  return document.body.clientHeight - 180 + 'px'
}

export function formatDate(date, fmt = 'yyyy-MM-dd') {
  if (!(date instanceof Array)) {
    date = new Date(date)
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  const o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  }
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      const str = o[k] + ''
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str))
    }
  }
  return fmt
}

function padLeftZero(str) {
  return ('00' + str).substr(str.length)
}
export function getBlob(response) {
  const blob = new Blob([response.data], {
    type: 'application/vnd.ms-excel'
  })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  var filename = decodeURI(response.headers.filename)
  // link.download = filename + '.xls'
  link.download = filename
  link.click()
}
// 图片 blob 流转化为可用 src
export function imgHandle(obj) {
  return window.URL.createObjectURL(obj)
}




// 过滤器
export let time = val => {
  var date = new Date(val) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
  var Y = date.getFullYear() + '-'
  var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
  var D = date.getDate() + ' '
  D = D < 10 ? ('0' + D) : D
  var h = date.getHours() + ':'
  h = h < 10 ? ('0' + h) : h
  var m = date.getMinutes()
  m = m < 10 ? ('0' + m) : m
  // var s = date.getSeconds()
  return Y + M + D + h + m
}

//秒转 时分秒
function formatFirst(value) {
  let result = parseInt(value)
  let h = Math.floor((result / 3600)) < 10 ? '0' + Math.floor((result / 3600)) : Math.floor((result / 3600))
  let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60))
  let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60))
  result = `${h}:${m}:${s}`
  return result
}


let fmatDate = dateTimeStamp => {
  //dateTimeStamp是一个时间毫秒，注意时间戳是秒的形式，在这个毫秒的基础上除以1000，就是十位数的时间戳。13位数的都是时间毫秒。
  var minute = 1000 * 60; //把分，时，天，周，半个月，一个月用毫秒表示
  var hour = minute * 60;

  var now = new Date().getTime(); //获取当前时间毫秒

  var diffValue = now - dateTimeStamp; //时间差
  var result;

  if (diffValue < 0) {
    return;
  }
  var minC = diffValue / minute; //计算时间差的分，时，天，周，月
  var hourC = diffValue / hour;

  if (hourC >= 1 && hourC <= 23) {
    result = " " + parseInt(hourC) + "小时前"
  } else if (minC >= 1 && minC <= 59) {
    result = " " + parseInt(minC) + "分钟前"
  } else if (diffValue >= 0 && diffValue <= minute) {
    result = "刚刚"
  } else {
    var timeMsg = new Date(dateTimeStamp);
    var year = timeMsg.getFullYear();
    var month = timeMsg.getMonth() + 1;
    var date = timeMsg.getDate();
    return year + "-" + month + "-" + date;
  }
  return result;
}

export let fmatStatus = val => {
  return val == 0 ? '已上架' : '未上架'
}
let fmatStatusTwo = val => {
  return val == 0 ? '已上架' : '未上架'
}
export let live = val => {
  return val == 0 ? "未开始" : "直播中"
}

export let livestatus = val => {
  if (val == 0) {
    return val = "未开始"
  } else if (val == 1) {
    return val = "直播中"
  } else if (val == 2) {
    return val = "直播结束,待录制"
  } else if (val == 3) {
    return val = "回放录制中"
  } else if (val == 4) {
    return val = "直播完成,录制完成"
  }
}
export let zb = val => {
  if (val == 1) {
    return val = "主播"
  } else if (val == 2) {
    return val = "助手"
  } else {
    return val = "观众"
  }
}
export let sp = val => {
  if (val == 1) {
    return val = "所有人"
  } else if (val == 2) {
    return val = "会员专属"
  } else {
    return val = "未设置"
  }
}
//0.待处理 1.通过 2.拒绝
export let zt = val => {
  if (val == 0) {
    return val = "待处理"
  } else if (val == 1) {
    return val = "通过"
  } else  if (val==2) {
    return val = "拒绝"
  }
}
let fmatRecommend = val => {
  return val == 0 ? '未推荐' : '已推荐'
}
let fmatPrice = val => {
  return val / 100
}

export function formeDate(value) { // 时间戳转换日期格式方法
  if (value == null) {
    return '';
  } else {
    let date = new Date(value);
    let y = date.getFullYear(); // 年
    let MM = date.getMonth() + 1; // 月
    MM = MM < 10 ? ('0' + MM) : MM;
    let d = date.getDate(); // 日
    d = d < 10 ? ('0' + d) : d;
    let h = date.getHours(); // 时
    h = h < 10 ? ('0' + h) : h;
    let m = date.getMinutes(); // 分
    m = m < 10 ? ('0' + m) : m;
    let s = date.getSeconds(); // 秒
    s = s < 10 ? ('0' + s) : s;
    return y + '-' + MM + '-' + d  
    // + ' ' + h + ':' + m + ':' + s;
  }
}
export function getLocalTime(nS) {
  let date = new Date(nS);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let h = date.getHours();
  let m = date.getMinutes();
  let s = date.getSeconds();
  h = h < 10 ? ('0' + h) : h;
  m = m < 10 ? ('0' + m) : m;
  s = s < 10 ? ('0' + s) : s;
  month = month < 10 ? "0" + month : month;
  day = day < 10 ? "0" + day : day;
  date = year + '-' + month + '-' + day + ' ' + h + ':' + m + ':' + s;
  // 2018-10-09
  return date;
}
export function dj(val) {
  if (val == 2) {
    return val='主会员 公司主账号'
  }
  else if (val == 3) {
    return val ='普通会员 公司子账号'
  }
  else if (val == 4) {
    return val ='普通用户'
  }
}
export function sex(val) {
  if (val == 0) {
    return val ='未知'
  }
  else if (val == 1) {
    return val = '男'
  }
  else if (val == 2) {
    return val ='女'
  }
}
//pdf转图片 状态 1.未开始 2.进行中 3.已完成 4.异常
export let spdf = val => {
  if (val == 1) {
    return val = "未开始"
  } else if (val == 2) {
    return val = "进行中"
  } else  if (val==3) {
    return val = "已完成"
  }
  else  if (val==4) {
    return val = "异常"
  }
}
export let ot = val => {
  if (val == 'ARTICLE') {
    return val ='资讯'
  }
  else if (val == "PDF") {
    return val ='PDF'
  }
  else if (val == 'WEBURL') {
    return val ='外部链接'
  }
}

export let fz = val => {
  if (val == 'ARTICLE') {
    return val ='资讯分组'
  }
  else if (val == "PDF") {
    return val ='PDF分组'
  }
  else if (val == 'COMPANY') {
    return val ='会员类别分组'
  }
  else if (val == 'ABOUTUS') {
    return val ='关于我们'
  }
}

