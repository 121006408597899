import { handleTree } from "../../util/util";
import { constantRoutes } from "@/router";
import Layout from "@/layout/layout";

import axios from "axios";
import Cookies from "js-cookie";

const user = {
  state: {
    roles: [],
    route: [],
    userName: "ZLtestName",
    menuList: [
      {
        path: "/Overview",
        meta: { name: "首页", icon: "dashboard" },
        component: "Layout",
        children:[
          {
            path: "/Overview",
            meta: { name: "数据概览", icon: "excel" },
            component: "Layout",
          },
        ]},

      {
        path: "/swiper",
        
        meta: { name: "内容管理", icon: "message" },
        component: Layout,
        children: [
          {
            path: "/swiper",
            meta: { name: "首页轮播", icon: "skill" },
            component: "Layout",
          },
          
          {
            path: "/pdfClassify",
            meta: { name: "PDF", icon: "pdf" },
            component: "Layout",
          },
          {
            path: "/recomendpdf",
            meta: { name: "推荐PDF", icon: "tab" },
            component: "Layout",
          },
        
    
          {
            path: "/aboutus",
            meta: { name: "资讯", icon: "lock" },
            component: "Layout",
          },
        ]
      },
     
      
       {
        path: "/rotationChart",
        meta: { name: "人员管理", icon: "people" },
        component: "Layout",
        children:[
          {
            path: "/rotationChart",
            meta: { name: "用户管理", icon: "peoples" },
            component: "Layout",
          },
        ]},
       {
        path: "/memberInformation",
        meta: { name: "会员管理", icon: "user" },
        component: "Layout",
        children:[
          {
            path: "/memberInformation",
            meta: { name: "会员信息", icon: "chart" },
            component: "Layout",
          },
       
        {
          path: "/service",
          meta: { name: "会员服务", icon: "nested" },
            component: "Layout",
        },
          {
            path: "/Membership",
            meta: { name: "会员申请", icon: "example" },
              component: "Layout",
          },
          {
            path: "/person",
            meta: { name: "通知联络人", icon: "message" },
              component: "Layout",
          },
         
        
          
        ]
      },
      {
        path: "/message",
        meta: { name: "系统内容", icon: "skill" },
        component: "Layout",
        children:[
          {
            path: "/message",
            meta: { name: "通用文本信息", icon: "component" },
            component: "Layout",
          },
          {
        path: "/concat",
        meta: { name: "联系方式", icon: "wechat" },
          component: "Layout",
          },
         
          
        ]
      },
      {
        path: "/ulog",
        meta: { name: "操作日志", icon: "setting" },
        component: "Layout",
        children:[
          {
            path: "/ulog",
            meta: { name: "操作日志", icon: "list" },
            component: "Layout",
          },
         
         
          
        ]
      },
      
 // {
      //   path: "/swiper",
      //   meta: { name: "主页", icon: "el-icon-s-home" },
      //   component: "Layout",
      //   children:[
      //     {
      //       path: "/swiper",
      //       meta: { name: "轮播图", icon: "el-icon-s-home" },
      //       component: "Layout",
      //     },
          
      //     {
      //       path: "/aboutus",
      //       meta: { name: "关于我们", icon: "el-icon-s-home" },
      //       component: "Layout"
      //     },
      //     {
      //       path: "/tags",
      //       meta: { name: "文章资讯标签", icon: "el-icon-s-home" },
      //       component: "Layout"
      //     },
      //     {
      //       path: "/tagsDetail",
      //       meta: { name: "文章资讯详情", icon: "el-icon-s-home" },
      //       component: "Layout"
      //     },
      //       {
      //                   path: "/contactus",
      //                   meta: { name: "联系我们", icon: "el-icon-s-home" },
      //                   component: "Layout"
      //        },
          
      //   ]
      // },
      // {
      //   path: "/getpdf",
      //   meta: { name: "pdf", icon: "el-icon-s-home" },
      //   component: "Layout",
      //   children:[
      //     {
      //       path: "/getpdf",
      //       meta: { name: "pdf信息", icon: "el-icon-s-home" },
      //       component: "Layout",
      //     },
      //     {
      //       path: "/pdfClassify",
      //       meta: { name: "pdf类型", icon: "el-icon-s-home" },
      //       component: "Layout",
      //     },
          
      //     {
      //       path: "/pdfShareCusList",
      //       meta: { name: "pdf分享记录", icon: "el-icon-s-home" },
      //       component: "Layout",
      //     },
          
      //   ]
      // },

     
      
    ],
  },

  mutations: {
    changeName: (state, userName) => {
      state.userName = userName;
    },
    SET_ROUTES: (state, routes) => {
      state.routes = constantRoutes.concat(routes);
    },
    SET_SIDEBAR_ROUTERS: (state, routers) => {
      state.menuList = state.menuList.concat(routers);
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles || [];
    },
  },

  actions: {
    // 获取个人信息
    // 获取用户信息
    // GetInfo({ commit, state }) {
    //   return new Promise((resolve, reject) => {
    //     axios({
    //       method: "get",
    //       url: "/getInfo",
    //       data: {
    //         token: Cookies.get("token"),
    //       },
    //     }).then((response1) => {
    //       console.log(response1.data);
    //       if (response1.data.code == 200) {
    //         commit("SET_ROLES", response1.data.data.roles);
    //         resolve(response1.data.data.roles);
    //       }
    //     });
    //   });
    // },
    // 获取后台路由列表
    // getRoutes({ state, commit }) {
    //   return new Promise((resolve) => {
    //     console.log(state);
    //     console.log(commit);
    //     // 向后端请求路由数据
    //     axios({
    //       method: "get",
    //       url: "/getMenuList",
    //       data: {
    //         roles: state.roles,
    //       },
    //     }).then((response2) => {
    //       if (response2.data) {
    //         console.log(response2.data);
    //         const data = handleTree(response2.data.data.data, "id");
    //         const sdata = JSON.parse(JSON.stringify(data));
    //         const rdata = JSON.parse(JSON.stringify(data));
    //         const sidebarRoutes = filterAsyncRouter(sdata);
    //         const rewriteRoutes = filterAsyncRouter(rdata, false, true);
    //         commit("SET_ROUTES", rewriteRoutes);
    //         commit("SET_SIDEBAR_ROUTERS", sidebarRoutes);
    //         resolve(rewriteRoutes);
    //       }
    //     });
    //   });
    // },
  },
};
// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap, lastRouter = false, type = false) {
  return asyncRouterMap.filter((route) => {
    if (type && route.children) {
      route.children = filterChildren(route.children);
    }
    if (route.component) {
      // Layout ParentView 组件特殊处理
      if (route.component === "Layout") {
        route.component = Layout;
      } else {
        route.component = loadView(route.component);
      }
    }
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children, route, type);
      if (route.children.length === 1 && route.children[0].path === "index") {
        route.alwaysShow = false;
      } else {
        route.alwaysShow = true;
      }
    } else {
      delete route["children"];
      delete route["redirect"];
    }
    return true;
  });
}

function filterChildren(childrenMap, lastRouter = false) {
  var children = [];
  childrenMap.forEach((el, index) => {
    if (el.children && el.children.length) {
      if (!el.component) {
        el.children.forEach((c) => {
          c.path = el.path + "/" + c.path;
          if (c.children && c.children.length) {
            children = children.concat(filterChildren(c.children, c));
            return;
          }
          children.push(c);
        });
        return;
      }
    }
    if (lastRouter) {
      el.path = lastRouter.path + "/" + el.path;
    }
    children = children.concat(el);
  });
  return children;
}

export const loadView = (view) => {
  // 路由懒加载
  return (resolve) => require([`@/views/${view}`], resolve);
};

export default user;
