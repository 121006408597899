import Vue from "vue";
import Router from "vue-router";
import Layout from "../layout/layout.vue";
// 使用VueRouter
Vue.use(Router);

//获取原型对象上的push函数
// const originalPush = Router.prototype.push;
// Router.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch((err) => err);
// };
// const originalReplace = Router.prototype.replace;

// Router.prototype.replace = function replace (location, onResolve, onReject) {
//   // if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
//   return originalReplace.call(this, location).catch(err => err)
// }
export const constantRoutes = [
  // 登录页

  { path: '/', redirect: '/login' },
  { path: '/login', name: 'login', component: () => import('../init/login.vue') },

  {
    path: "/index",
    redirect: '/Overview',
    meta: { name: "内容管理", icon: "el-icon-home" },
    component: Layout,
    children: [
   
      {
        path: "/Overview",
        meta: { name: "数据概览", icon: "el-icon-user" },
        component: () => import('../views/Overview/Overview.vue')
      },
      {
        path: "/swiper",
        meta: { name: "首页轮播", icon: "el-icon-user" },
        component: () => import('../views/content/swiper.vue')
      },
      
      {
        path: "/pdfClassify",
         meta: { name: "PDF", icon: "el-icon-s-home" },
        component: () => import("../views/content/pdf.vue")
      },
      {
        path: "/recomendpdf",
         meta: { name: "推荐PDF", icon: "el-icon-s-home" },
        component: () => import("../views/content/rpdf.vue")
      },
      {
        path: "/classification",
         meta: { name: "内容分类", icon: "el-icon-s-home" },
        component: () => import("../views/content/class.vue")
      },
      {
        path: "/addaboutus",
    
        component: () => import("../views/content/addzx.vue")
      },

      {
        path: "/aboutus",
        meta: { name: "资讯", icon: "el-icon-s-home" },
        component: () => import('../views/content/information.vue')
      },
        //人员管理
        {
          path: '/rotationChart',
          meta: { name: "用户管理", icon: "el-icon-s-home" },
          component: () => import('../views/user/rotationChart.vue')
        },
       {
        path: "/message",
        meta: { name: "通用文本信息", icon: "el-icon-s-home" },
        component: () => import('../views/system/message.vue')

      },
      {
        path: "/addmessage",
     
        component: () => import('../views/system/addmessage.vue')

      },
      {
        path: "/concat",
        meta: { name: "联系方式", icon: "el-icon-s-home" },
        component: () => import('../views/system/contact.vue')

      },
      {
        path: "/ulog",
        meta: { name: "操作日志", icon: "el-icon-s-home" },
        component: () => import('../views/ULog/ULog.vue')

      },
      {
        path: "/memberInformation",
        meta: { name: "会员信息", icon: "el-icon-s-home" },
        component: () => import('../views/memberManagement/memberInformation.vue')

      },
      
      {
        path: "/addEditClass",
        component: () => import('../views/memberManagement/addEditClass.vue')

      },
      {
        path: "/Memberaccount",
        meta: { name: "会员账户", icon: "el-icon-s-home" },
        component: () => import('../views/memberManagement/Memberaccount.vue')

      },
      {
        path: "/Membership",
        meta: { name: "会员申请", icon: "el-icon-s-home" },
        component: () => import('../views/memberManagement/Membership.vue')

      },


      // {
      //   path: "/Companytags",
      //   meta: { name: "会员标签", icon: "el-icon-s-home" },
      //   component: () => import('../views/company/Companytags.vue')

      // },
      
      // {
      //   path: "/CompanytagsDeatil",
      //   meta: { name: "会员列表", icon: "el-icon-s-home" },
      //   component: () => import('../views/company/CompanytagsDeatil.vue')
      // },
      {
        path: "/companyCards",
        meta: { name: "会员名片", icon: "el-icon-s-home" },
        component: () => import('../views/memberManagement/companyCards.vue')
      },
      {
        path: "/service",
        meta: { name: "会员服务", icon: "el-icon-s-home" },
        component: () => import('../views/memberManagement/service.vue')
      },
      {
        path: "/person",
        meta: { name: "通知联络人", icon: "el-icon-s-home" },
        component: () => import('../views/memberManagement/person.vue')
      },
      {
        path: "/managementvip",
        meta: { name: "会员分类管理", icon: "el-icon-s-home" },
        component: () => import('../views/class/vip.vue')
      },
      {
        path: "/managementpdf",
        meta: { name: "PDF分类管理", icon: "el-icon-s-home" },
        component: () => import('../views/class/pdf.vue')
      },
      {
        path: "/managementarticle",
        meta: { name: "资讯分类管理", icon: "el-icon-s-home" },
        component: () => import('../views/class/article.vue')
      },
      {
        path: "/managementabout",
        meta: { name: "关于我们分类管理", icon: "el-icon-s-home" },
        component: () => import('../views/class/about.vue')
      },

      //     


      
        
     
     
     
    
    ],
  },





];

export default new Router({
  mode: "hash", 
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes,
});
