<template>
  <div>
    <searchForm inLine :form="form" :formLabel="formLabel">
      <el-button icon="el-icon-search" size="mini" type="primary"
        >搜索</el-button
      >
      <el-button icon="el-icon-refresh" size="mini" type="warning"
        >重置</el-button
      >
    </searchForm>
    <pageTable
      :tableSetting="tableSetting"
      @handleClick="handleClick"
      ref="resTable"
    >
    </pageTable>
   
  </div>
</template>
<script>
import pageTable from "../components/pageTable.vue";
import searchForm from "../components/searchForm.vue";
export default {
  name: "redirect",
  components: {
    pageTable,
    searchForm,
  },
  data() {
    return {
      form: { user: "123" },
      formLabel: [
        {
          clearable: true,
          model: "user",
          label: "用户名称",
        },
      ],
      tableSetting: {
        params: { curPage: 1, pageSize: 10 }, //默认请求参数
        urlName: "/user/data", //请求url
        pagination: true,
        selection: true,
        isIndex: true,
        tableList: [
          {
            showTooltip: true,
            prop: "user",
            label: "用户名称",
            changeValue: (row) => {
              return row.user + "哈哈";
            },
          },
          {
            type: "img",
            showTooltip: true,
            prop: "logo",
            label: "用户头像",
          },
          {
            showTooltip: true,
            prop: "phone",
            label: "电话号码",
          },
          {
            label: "操作",
            button: [
              {
                type: "primary",
                label: "编辑",
                btnName: "update",
                isDisabled: (row) => {},
              },
              {
                type: "danger",
                label: "删除",
                btnName: "delete",
                isDisabled: (row) => {},
              },
            ],
          },
        ],
      },
    };
  },
  created() {
    console.log("重新加载了:重定向页");
  },
  methods: {
    handleClick(obj) {
      console.log(obj.btnName);
      this[obj.btnName](obj.row);
    },
    update(row) {
      console.log("成功了1");
      console.log(row);
    },
    delete(row) {
      console.log("成功了2");
      console.log(row);
    },
    // 搜索函数
    serach() {
      this.tableSetting.params = { ...this.tableSetting.params, ...this.form };
      this.$refs.resTable.reload();
    },
  },
};
</script>